import React from "react";
import { Grid, Typography, Card, CardContent, LinearProgress } from "@mui/material";

const data = [
  {
    market: "Who will win Dem nomination for NYC Mayor?",
    options: [
      { name: "Andrew Cuomo", chance: 38 },
      { name: "Eric Adams", chance: 34 },
    ],
    totalBet: "$254",
  },
  {
    market: "How many SpaceX launches in 2025?",
    options: [
      { name: "<100", chance: 21 },
      { name: "100-119", chance: 32 },
    ],
    totalBet: "$182",
  },
];

const MarketTable = () => {
  return (
    <div style={{ padding: "20px", backgroundColor: "#121212", color: "white" }}>
      {data.map((item, index) => (
        <Card key={index} style={{ marginBottom: "20px", backgroundColor: "#1e1e1e" }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" style={{ color: "#87CEEB" }}>
                  {item.market}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {item.options.map((option, idx) => (
                  <Grid
                    container
                    key={idx}
                    alignItems="center"
                    style={{ marginBottom: "10px" }}
                  >
                    <Grid item xs={4}>
                      <Typography>{option.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <LinearProgress
                        variant="determinate"
                        value={option.chance}
                        style={{ height: "10px", borderRadius: "5px" }}
                      />
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "right" }}>
                      <Typography>{option.chance}%</Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" style={{ textAlign: "right" }}>
                  Total Bet: {item.totalBet}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default MarketTable;

import React, { useState } from "react";

const ComponentSwitcher = ({ render }) => {
  const [activeComponent, setActiveComponent] = useState("comp1");

  const toggleComponent = () => {
    setActiveComponent((prev) => (prev === "comp1" ? "comp2" : "comp1"));
  };

  return <>{render(activeComponent, toggleComponent)}</>;
};

export default ComponentSwitcher;

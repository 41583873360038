import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Avatar } from "@mui/material";
import { styles } from "../../Css/styles";
import { useDispatch } from "react-redux";
import { addBet, removeBet } from "../../../../redux/features/valuemarketSlice";
import { useSelector } from "react-redux";
import { probabilityToAmericanOdds, safeParseJSON } from "../../../../Utils/valuemakets";
import Gauge from "../Markets/CircleProgress";

const MarketList = ({
  image,
  marketId,
  eventId,
  name,
  outcome,
  token,
  price,
  uiType,
  eventName,
  singleMarket,
  bestBid,
  filterType,
  handleOpen
}) => {
  const [hoverIndex, setHoverIndex] = useState(null);
  const dispatch = useDispatch();
  const valueMarketDetails = useSelector(
    (state) => state.valuemarket.valueMarketDetails
  );

  const { bet_info, bets } = valueMarketDetails;

  let parsedOutcomes = [];
  let parsedOutcomePrices = [];
  let parsedOutcomeId = [];

  try {
    parsedOutcomes = safeParseJSON(outcome) || [];
  } catch {
    console.error("Invalid JSON in 'outcome'");
  }

  try {
    parsedOutcomePrices = safeParseJSON(price) || [];
  } catch {
    console.error("Invalid JSON in 'price'");
  }

  try {
    parsedOutcomeId = safeParseJSON(token) || [];
  } catch {
    console.error("Invalid JSON in 'price'");
  }

  const combinedData = parsedOutcomes.map((outcome, index) => ({
    outcome,
    price: parsedOutcomePrices[index],
    token: parsedOutcomeId[index]
  }));

  const valueType = (item) => {
    const existingBet = bets.find((bet) => bet.odd_id === item.token);

    if (existingBet) {
      // If the token exists, remove the bet
      dispatch(removeBet({ odd_id: existingBet.odd_id }));
    } else {
      let odd_money = probabilityToAmericanOdds(item.price);
      let subtitle = `Moneyline ${odd_money < 0 ? "" : "+"} ${odd_money} for ${item.outcome} - ${item.name}`;
     
      const newBet = {
        event_id: item.eventId,
        market_id: item.marketId || "",
        odd_id: item.token || "",
        avg_price: odd_money || "",
        avg_price_actual:item.price,
        outcome: item.outcome,
        eventName: item.eventName,
        sub_title:subtitle,
        init_sub_title:subtitle,
        marketName: item.name,
      };
      dispatch(addBet(newBet));
    }
  };


  return (
    <Box sx={{...styles.memberSection,mb:uiType === "OUTSIDE" ? 0 :1,flexDirection:!singleMarket.isSingle ? 'row' : 'column'}}>
      <Box sx={{ ...styles.top, alignItems: "center" }}>
        {uiType === "OUTSIDE" && (
          <Avatar src={image} sx={styles.image} style={{ cursor: "pointer" }} />
        )}
        {!singleMarket.isSingle && <Typography onClick={handleOpen} sx={{...styles.memberName,fontSize:uiType === "OUTSIDE" ? '16px' : '12px'}}>{name}</Typography>}
      </Box>
     
      <Box sx={{ display: "flex", gap: 1,maxWidth:singleMarket.isSingle ? '100%' : 'auto',
                width:singleMarket.isSingle ? '100%' : 'auto' }}>
        {combinedData.map((data, index) => {
          let odd_money = (filterType === "americanOdds" || uiType !== "OUTSIDE") ? probabilityToAmericanOdds(Number(data.price)) : `${(data.price * 100).toFixed(2)}%`;
          const isSelected = bets.some((x) => x.odd_id === data.token);
          return (
          <Box
              key={index}
              sx={styles.flexContainerStyles(singleMarket)}
            >
              <Button
                onClick={() =>
                  valueType({ ...data, marketId, eventId, name, eventName })
                }
                sx={styles.statusStyles(isSelected, hoverIndex, index, data, uiType, singleMarket)}
              >
                  <div className="d-flex justify-content-around w-100">
                      <div style={{
                            textOverflow: 'ellipsis',
                            maxWidth: '40px',
                            overflow: 'hidden'
                      }}>{data.outcome}</div>
                      <div>{filterType === "americanOdds" && (odd_money < 0 ? "" : "+")}{odd_money}</div>
                  </div>
              </Button>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default MarketList;
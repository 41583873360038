import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styles } from "../../Css/styles";
import { useDispatch, useSelector } from "react-redux";
import { setClearEventData, setEventLoading, setFirstTimeLoader } from "../../../../redux/features/valuemarketSlice";

const SliderButtons = ({ data, handleSlugChange, slug }) => {
  const dispatch = useDispatch();
  const scrollContainerRef = useRef(null);
  const { isCalling, mainCategory, subSlug, } = useSelector((state) => ({
    isCalling: state.valuemarket.isCalling,
    mainCategory: state.valuemarket.mainCategory,
    subSlug : state.valuemarket.subSlug
  }));
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const handleScroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = direction === "left" ? -150 : 150;
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const updateScrollButtonsState = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const { scrollLeft, scrollWidth, offsetWidth } = container;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + offsetWidth < scrollWidth);
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;

    if (container) {
      container.addEventListener("scroll", updateScrollButtonsState);
      updateScrollButtonsState();

      return () => {
        container.removeEventListener("scroll", updateScrollButtonsState);
      };
    }
  }, [scrollContainerRef, data]);

  useEffect(() => {
    if (!data && !isCalling) return;
    if (subSlug) handleSlugChange(subSlug);
    else handleSlugChange(mainCategory);
  }, [data, isCalling]);

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", gap: 1, maxWidth: "100%" }}
    >
      <IconButton
        onClick={() => handleScroll("left")}
        disabled={!canScrollLeft}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <Box ref={scrollContainerRef} sx={styles.buttonScroll}>
        {(
          <Button
            variant="contained"
            onClick={() => handleSlugChange(mainCategory)} // Handle "all" click
            sx={styles.buttonStyles(slug, data,mainCategory)}
          >
            Top
          </Button>
        )}
        {data?.map((item, index) => (
          <Button
            key={index}
            variant="contained"
            onClick={() => {handleSlugChange(item.slug); dispatch(setClearEventData())}}
            sx={styles.menuButtonStyles(slug, item,subSlug)}
          >
            {item.label}
          </Button>
        ))}
      </Box>
      <IconButton
        onClick={() => handleScroll("right")}
        disabled={!canScrollRight}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default SliderButtons;

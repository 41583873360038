import {
  getValuebet,
  POLYMARKETS_FILTERS_LISTS,
  POLYMARKETS_LISTS,
  POLYMARKETS_LISTS_SEARCH,
  POLYMARKETS_SLUG_LISTS,
} from "../../API-Handle/services/auth_curd";
import { setEventsCheck, setEventsData, setFiltersData, setSlugData } from "../features/valuemarketSlice";


export const getAllMarketSlugList = (param) => {
  return async (dispatch) => {
    let URL = `${POLYMARKETS_SLUG_LISTS}${param}`;
    let res = await getValuebet(URL);
    dispatch(setSlugData({ data: res }));
  };
};


export const getEventsList = (params, append = false) => {
  return async (dispatch) => {
    try {
      // Convert params to query string
      let queryString = new URLSearchParams(params).toString();
      // Destructure _q from params
      const { _q } = params || {};
      
      if(queryString.includes('tag_slug=all')) {
        queryString = queryString.replaceAll('tag_slug=all','')
      }

      console.log(queryString)
      
      // Determine the URL based on the presence of _q
      const baseURL = _q?.length > 0 ? POLYMARKETS_LISTS_SEARCH : POLYMARKETS_LISTS;
      const URL = `${baseURL}?${queryString}`;

      // Fetch data from the API
      const res = await getValuebet(URL);

      // Extract relevant data and dispatch it
      const data = _q?.length > 0 ? res.events : res;
      dispatch(setEventsCheck(data.length === 0));
      dispatch(setEventsData({ data, append }));
    } catch (error) {
      // Log errors with additional context
      console.error("Error fetching events:", { params, append, error });
      return; // Prevent further execution on error
    }
  };
};





export const getAllMarketFiltersList = () => {
  return async (dispatch) => {
    let URL = `${POLYMARKETS_FILTERS_LISTS}`;
    let res = await getValuebet(URL);
    dispatch(setFiltersData({ data: res }));
  };
};

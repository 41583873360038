import React, { useContext, useEffect, useState } from "react";
import NavigationBar from "../../Components/SportsbookHeader/NavigationBar";
import Odds from "./Events/Markets";
import { useLocation } from "react-router-dom";
import ValuebetContext from "../../context/Valuebet/ValuebetContext";
import Bets from "./Bets";
import { useDispatch, useSelector } from "react-redux";
import { clearBets, mobileViewBtn, setFirstSectionToggle, setMobileScreen } from "../../redux/features/valuemarketSlice";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import Sidebar from "./Events/Markets/Sidebar";
import { getAllMarketSlugList } from "../../redux/services/valuebetmarketServices";
import ComponentSwitcher from "./Events/Common/ComponentSwitcher";
import Details from "./Events/MarketDetails";

function Index() {
  const { getSports } = useContext(ValuebetContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const [eventDetails,setEventDetails] = useState()
  const smallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"))
  const valueMarketDetails = useSelector(
    (state) => state.valuemarket.valueMarketDetails
  );
  const mobileView = useSelector((state) => state.valuemarket.mobileView);
   const firstSection = useSelector(
          (state) => state.valuemarket.firstSection
        );
        const mobileScreen = useSelector(
                      (state) => state.valuemarket.mobileScreen
                    );
  const { bets } = valueMarketDetails;

  useEffect(() => {
    let club_id = location?.state?.club?.id;
    getSports(club_id);
    // eslint-disable-next-line
  }, []);

  const passDatatoParent = (data) =>{
   setEventDetails(data)
  }


  return (
    <React.Fragment>
      <NavigationBar />
      <div className="sportbook-container-fluid">
        <Sidebar mobView={mobileScreen.step1}/>
        {/* <Odds mobView={mobileScreen.step2}/> */}

        <ComponentSwitcher
      render={(activeComponent,toggleComponent) =>
        activeComponent === "comp1" ? 
        <Odds passDatatoParent={passDatatoParent} onSwitch={toggleComponent} mobView={mobileScreen.step2}/> :
         <Details data={eventDetails} onSwitch={toggleComponent}/>
      }
    />

        <Bets mobView={mobileScreen.step3}/>
          {mobileScreen.step2 && bets.length !== 0 && <div className="bet-button-box position-absolute">
            <button
              className="bet-btn"
              onClick={() => dispatch(setMobileScreen("step3"))
              }
            >
              Bets ({bets.length})
            </button>
          </div>}
        {/* )} */}
        {/* {(!firstSection && smallScreen) && ( */}
          {mobileScreen.step1 && <div className="bet-button-box position-absolute">
            <button
              className="bet-btn"
              onClick={() => dispatch(setMobileScreen("step2"))}            >
              Continue
            </button>
          </div>}
        {/* )} */}
        
      </div>
    </React.Fragment>
  );
}

export default Index;
import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Button,
  ButtonGroup,
  Container,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import moment from "moment";

const timeIntervals = [
  { label: "1H", value: "1h",fidelity:"1" },
  { label: "6H", value: "6h",fidelity:"1" },
  { label: "1D", value: "1d",fidelity:"5" },
  { label: "1W", value: "1w",fidelity:"30" },
  { label: "1M", value: "1m",fidelity:"180" },
  { label: "ALL", value: "all",fidelity:"720" },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#212121",
          fontFamily: "inherit",
          padding: "10px",
          border: "1px solid #d52b00",
          borderRadius: "5px",
        }}
      >
        <p>
          <span style={{ color: "#d52b00" }}>Time:</span>{" "}
          {payload[0].payload.time}
        </p>
        <p>
          <span style={{ color: "#d52b00" }}>Price:</span>{" "}
          {payload[0].value.toFixed(2)}
        </p>
      </div>
    );
  }
  return null;
};

const MarketGraphSingle = ({ marketId }) => {
  const [data, setData] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState(timeIntervals[5]);
  const [loading, setLoading] = useState(true);

  const fetchData = async (interval) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://clob.polymarket.com/prices-history?interval=${interval.value}&market=${marketId[0]}&fidelity=${interval.fidelity}`
      );
      if (response.data) {
        setData(
          response.data.history.map((point) => {
            let t = moment.utc(point.t * 1000);
            return ({
            time: ['1h','6h','1d'].includes(interval.value) ? t.format('hh:mm a') : t.format('MMM D'),
            price: point.p * 100,
          })}
        )
        );
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(selectedInterval)
    fetchData(selectedInterval);
  }, [selectedInterval]);

  const minPercentage = Math.min(...data.map(item => item.price));
const maxPercentage = Math.max(...data.map(item => item.price));


  return (
    <Container
      style={{ textAlign: "center", marginTop: "20px", maxHeight: "500px" }}
    >
      {loading ? (
        <CircularProgress style={{ marginTop: "20px" }} />
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart
            data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="0 3" />
            <XAxis dataKey="time" />
            <YAxis domain={[minPercentage, maxPercentage]}/>
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="monotone"
              dataKey="price"
              strokeWidth={2}
              stroke="#d52b00"
              fill="#000"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
      <ButtonGroup variant="contained" sx={{ mt: 3 }}>
        {timeIntervals.map((interval) => (
          <Button
            sx={{
              color: "#fff",
              background:
                selectedInterval.value === interval.value ? "#d52b00" : "#363535",
              borderColor: "#999!important",
              "&:hover": {
                background: "#d52b00",
              },
            }}
            key={interval.value}
            onClick={() => setSelectedInterval(interval)}
            color={
              selectedInterval === interval.value ? "primary" : "secondary"
            }
          >
            {interval.label}
          </Button>
        ))}
      </ButtonGroup>
    </Container>
  );
};

export default MarketGraphSingle;

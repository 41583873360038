import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  CardContent,
  Divider,
  Grid,
  Tab,
  Tabs,
  TextField,
  AccordionSummary,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Card } from "@mui/material";
import { useParams } from "react-router";
import { styles } from "../../Css/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  formatToMillions,
  formatValue,
  probabilityToAmericanOdds,
  safeParseJSON,
} from "../../../../Utils/valuemakets";
import MarketList from "../Common/MarketList";
import NavigationBar from "../../../../Components/SportsbookHeader/NavigationBar";
import TradeCard from "./TradeCard";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { BASEURL } from "../../../../API-Handle/config";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Bets from "../../Bets";
import Sidebar from "../Markets/Sidebar";
import MarketGraph from "./Graph";
import {
  ArrowBack,
  ArrowBackIos,
  ArrowBackIosRounded,
} from "@mui/icons-material";
import moment from "moment";
import OrderBook from "./OrderBook";
import GraphData from "./GraphData";
import MarketGraphSingle from "./GraphData";
import MarketGraphStacked from "./StackGraph";

const Details = ({ data, onSwitch }) => {
  const [expanded, setExpanded] = useState(null);
  const [tabValue, setTabValue] = useState({});
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterType, setFilterType] = useState("americanOdds"); // Default American Odds
  const [top4ClobTokenIds, setTop4ClobTokenIds] = useState([]);
  const [open, setOpen] = useState(false);

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handleAccordionChange = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const handleTabChange = (index, newValue) => {
    setTabValue((prev) => ({ ...prev, [index]: newValue }));
  };
  const [selectedOption, setSelectedOption] = useState(null);

  const valueType = (item) => {
    setSelectedOption((prev) => (prev === item ? null : item));
  };

  useEffect(() => {
    if (data?.markets) {
      const sortedTop4Ids = data.markets
        .map((row) => {
          const formattedPrice = JSON.stringify([
            row.bestAsk.toString(),
            (1 - row.bestAsk - row.spread).toFixed(4),
          ]);
          return { ...row, formattedPrice };
        })
        .sort((a, b) => {
          const pricesA = safeParseJSON(a.formattedPrice) || [];
          const pricesB = safeParseJSON(b.formattedPrice) || [];
          const maxPriceA =
            pricesA.length > 0 ? Math.max(...pricesA.map(Number)) : 0;
          const maxPriceB =
            pricesB.length > 0 ? Math.max(...pricesB.map(Number)) : 0;
          return sortOrder === "asc"
            ? maxPriceA - maxPriceB
            : maxPriceB - maxPriceA;
        })
        .slice(0, 4) // Sorting ke baad top 4 records lo
        .map((row) => safeParseJSON(row.clobTokenIds)?.[0]); // Sirf 0 index wali clobTokenId lo

      setTop4ClobTokenIds(sortedTop4Ids);
    }
  }, [data, sortOrder]);

  const handleOpen = () => {
    setOpen(!open)
  }


  return (
    <React.Fragment>
      <div className="sportbook-container-fluid">
        <Grid
          spacing={2}
          mt="65px"
          ml={0}
          mr={1}
          sx={{
            maxWidth: "100%",
          }}
        >
          <Grid
            container
            spacing={1}
            my={1}
            sx={{
              ml: {
                xs: 0,
                sm: 0,
                lg: 1,
                md: 1,
                xl: 2,
              },
              overflowY: "auto",
              pr: 1,
              width: {
                xs: "100%",
              },
            }}
          >
            <Box
              sx={{
                bgcolor: "#0b0b0b",
                color: "#fff",
                minHeight: "calc(100vh - 70px)",
                maxHeight:'calc(100vh - 70px)',
                overflowY:'auto',
                p: 3,
                width: "100%",
              }}
            >
              <Box sx={{ ...styles.top, mb: 3 }}>
                <Button onClick={onSwitch}  sx={{fontSize:'2rem', minWidth: "auto",color:'#d52b00' }}>
                  <ArrowBackIcon fontSize="large" sx={{ color: "red" }} />
                </Button>
                <img
                  onClick={() => setSelectedOption(data.id)}
                  src={data.image}
                  style={{
                    maxWidth: "40px",
                    maxHeight: "40px",
                    minHeight: "40px",
                    cursor: "pointer",
                  }}
                />
                <Typography sx={{ fontSize: "22px", fontFamily: "inherit" }}>
                  {data.title}
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontSize: "16px",
                  mb: "0.5rem",
                  alignItems: "center",
                  fontFamily: "inherit",
                  gap: "0.2rem",
                  display: "flex",
                }}
              >
                <CalendarMonthIcon fontSize="medium" />
                Est. End Date : {moment.utc(data.endDate).format("MMM Do YYYY")}
              </Typography>

              <Grid container spacing={3}>
                {/* Left Panel - Graph Section */}
                <Grid item xs={12} md={12}>
                  <Card sx={{ background: "#161313" }}>
                    <CardContent>
                      <Typography variant="h6">Graph Placeholder</Typography>
                      <Divider sx={{ my: 2 }} />
                      {/* Replace this with the graph component */}
                      <Box
                        sx={{
                          bgcolor: "#000",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 2,
                        }}
                      >
                        <MarketGraphStacked tokenIds={top4ClobTokenIds} />
                      </Box>
                    </CardContent>
                  </Card>
                  <Box>
                    <Box
                      sx={{
                        my: 2,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormControl sx={{ minWidth: 200 }}>
                        <Select
                          value={filterType}
                          onChange={(e) => setFilterType(e.target.value)}
                          size="small"
                        >
                          <MenuItem value="americanOdds">
                            Odd: American Odds
                          </MenuItem>
                          <MenuItem value="probability">
                            Odd: Probability
                          </MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl sx={{ minWidth: 200 }}>
                        <Select
                          value={sortOrder}
                          onChange={handleSortChange}
                          size="small"
                        >
                          <MenuItem value="asc">Price: Low to High</MenuItem>
                          <MenuItem value="desc">Price: High to Low</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box>
                    {data?.markets
                      ?.map((row) => {
                        // Format price correctly
                        const formattedPrice = JSON.stringify([
                          row.bestAsk.toString(),
                          (1 - row.bestAsk - row.spread).toFixed(4),
                        ]);

                        return { ...row, formattedPrice };
                      })
                      .sort((a, b) => {
                        // Parse formattedPrice correctly before sorting
                        const pricesA = safeParseJSON(a.formattedPrice) || [];
                        const pricesB = safeParseJSON(b.formattedPrice) || [];
                        const maxPriceA =
                          pricesA.length > 0
                            ? Math.max(...pricesA.map(Number))
                            : 0;
                        const maxPriceB =
                          pricesB.length > 0
                            ? Math.max(...pricesB.map(Number))
                            : 0;
                        return sortOrder === "asc"
                          ? maxPriceA - maxPriceB
                          : maxPriceB - maxPriceA;
                      })
                      .filter((row) => {
                        const prices =
                          safeParseJSON(row.formattedPrice)?.map(Number) || [];

                        return prices.some((price) => {
                          const probability = parseFloat(price);
                          const americanOdds =
                            filterType === "americanOdds"
                              ? probabilityToAmericanOdds(probability)
                              : probability;
                          return (
                            !isNaN(americanOdds) &&
                            americanOdds > 0.0001 &&
                            americanOdds !== 0 &&
                            americanOdds !== 1
                          );
                        });
                      })
                      .map((row, index) => (
                        <Card
                          key={row.id || index}
                          sx={{
                            background:
                              "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
                            mt: index === 0 ? 0 : 1,
                          }}
                        >
                          <CardContent
                            sx={{
                              pb: "0rem!important",
                              pt: "0rem!important",
                              px: 0,
                            }}
                          >
                            <Accordion
                              expanded={expanded === index}
                              onChange={() =>  handleAccordionChange(index)}
                              sx={{ mt: 1 }}
                            >
                              <AccordionSummary
                                sx={{
                                  width: "100%",
                                  ".MuiAccordionSummary-content": {
                                    width: "100%",
                                    "> div": {
                                      width: "100%",
                                    },
                                  },
                                }}
                              >
                                <MarketList
                                  key={row.id || index}
                                  singleMarket={{
                                    isSingle: data?.markets?.length <= 1,
                                    bestBid: row.bestBid,
                                    image: data.image,
                                    title: data.title,
                                  }}
                                  marketId={row.id}
                                  image={row.image}
                                  eventId={data.id}
                                  handleOpen={handleOpen}
                                  bestBid={row.bestBid}
                                  eventName={data.title}
                                  name={row.groupItemTitle}
                                  outcome={row.outcomes}
                                  token={row.clobTokenIds}
                                  price={row.formattedPrice}
                                  valueType={valueType}
                                  uiType={"OUTSIDE"}
                                  filterType={filterType}
                                />
                              </AccordionSummary>
                              <AccordionDetails sx={{ maxWidth: "100%" }}>
                                {expanded === index && (
                                  <MarketGraphSingle
                                    marketId={safeParseJSON(row.clobTokenIds)}
                                  />
                                )}{" "}
                              </AccordionDetails>
                            </Accordion>
                          </CardContent>
                        </Card>
                      ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default Details;

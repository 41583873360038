import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Straight from "./Straight";
import Parlay from "./Parlay";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import {
  mobileViewBtn,
  setMobileScreen,
  updateBetType,
} from "../../../redux/features/valuemarketSlice";

export default function Index({mobView}) {
  const dispatch = useDispatch();
  const valueMarketDetails = useSelector(
    (state) => state.valuemarket.valueMarketDetails
  );

  const bet_types = [
    {
      id: "Straight",
      title: "Straight",
    },
    // {
    //   id: "Parlay",
    //   title: "Parlay",
    // },
  ];

  const changeBet = (type) => {
    dispatch(updateBetType(type));
  };

  return (
    <Box
      className={"side-right"}
      sx={{
        display: {
          xs: mobView  ? 'block' : 'none',
          sm: mobView ? 'block' : 'none',
          lg: "block",
          xl: "block",
          md:'block'
        },
      }}
    >
      <div className="d-flex justify-content-between mb-2">
        <div className="side-hader-option">
          {bet_types.map((item) => (
            <button
              item={item}
              key={item.id}
              className={
                item.id === valueMarketDetails?.bet_info?.bet_type
                  ? "head-button-active"
                  : "head-button"
              }
              onClick={() => changeBet(item.id)}
            >
              {item.title}
            </button>
          ))}
        </div>
        <button
          className="close-button-right ms-2"
              onClick={() => dispatch(setMobileScreen("step2"))}
        >
          <CloseIcon fontSize="inherit" />
        </button>
      </div>
      {valueMarketDetails?.bets?.length ? (
        <div
          className="py-3 pe-2"
          style={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}
        >
          {valueMarketDetails?.bet_info?.bet_type === "Straight" ? (
            <Straight betDetails={valueMarketDetails} />
          ) : valueMarketDetails?.bet_info?.bet_type === "Parlay" ? (
            <Parlay betDetails={valueMarketDetails} />
          ) : null}
        </div>
      ) : (
        <div className="px-3 py-4 text-center bg-black text-light rounded ">
          <b> Your bet slip is empty </b>
          <br />
          <br />
          <label>
            {" "}
            please make one or more selections in order to place bets{" "}
          </label>
        </div>
      )}
    </Box>
  );
}
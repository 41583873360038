import React, { useContext, useEffect, useState } from "react";
import ValuebetContext from "../../../../context/Valuebet/ValuebetContext";
import { Grid, Typography, Button } from "@mui/material";
import { getAllMarketFiltersList, getAllMarketSlugList } from "../../../../redux/services/valuebetmarketServices";
import { useDispatch, useSelector } from "react-redux";
import {  setClearEventData, setFirstTimeLoader, setIsCalling, setMainCategory, setSearchKeyword, setSubSlug } from "../../../../redux/features/valuemarketSlice";
import { styles } from "../../Css/styles";

export default function Sidebar({mobView}) {
  const dispatch = useDispatch();
  const { toggleFilter, filtersData, mainCategory,isCalling,subSlug } = useSelector((state) => ({
    toggleFilter: state.valuemarket.toggleFilter,
    filtersData: state.valuemarket.filtersData.data,
    isCalling: state.valuemarket.isCalling,
    mainCategory: state.valuemarket.mainCategory,
    subSlug:state.valuemarket.subSlug
  }));
  
  const getSlugByFilterSlug = (slug) => {
    if(slug !== mainCategory){
      dispatch(setClearEventData())
      dispatch(setSubSlug(''))
    }
    dispatch(setIsCalling(true))
    dispatch(setMainCategory(slug))
    dispatch(setFirstTimeLoader(false))
    dispatch(setSearchKeyword(slug))
  };

  useEffect(()=>{
    if(!filtersData){
      dispatch(getAllMarketFiltersList());
    }else{
      // dispatch(setMainCategory(filtersData[0]?.slug))
    }
  },[])

  useEffect(()=>{
    if(subSlug) return
    if(mainCategory){
      dispatch(getAllMarketSlugList(`?tag=${mainCategory}&status=active`))
    }
  
  },[mainCategory])

  return (
    <Grid
      item
      sx={styles.filterStyles(toggleFilter, mobView)}
      mt="71px"
    >
      {Array.isArray(filtersData) && filtersData.length > 0 ? (
        filtersData.map((item, index) => (
          <Button
            onClick={() => getSlugByFilterSlug(item.slug)}
            sx={{
              background: "transparent",
              justifyContent: "start",
              display: "flex",
              alignItems: "start",
              textTransform:'uppercase',
              color: item.slug === mainCategory ? '#d52b00' : "white",

              "&:hover": {
                background: "transparent",
                color: "red",
              },
            }}
            key={index}
          >
            <Typography
              variant="body"
              sx={{
                fontSize: "14px",
                textAlign: "start",
                whiteSpace: "nowrap",
                fontFamily: "inherit",
                textTransform: "uppercase",
              }}
            >
              {item.label}
            </Typography>
          </Button>
        ))
      ) : (
        <Typography
          sx={{
            color: "white",
            textAlign: "center",
            fontSize: "14px",
            fontFamily: "inherit",
          }}
        >
          No filters available
        </Typography>
      )}
    </Grid>
  );
}
export const styles = {
  box: {
    overflowY: "auto",
  },
  cardStyle: {
    borderRadius: "8px",
    minHeight: "200px",
    border:'1px solid #222',
    background:
      "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
    maxHeight: "200px",
    position: "relative",
  },
  top: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "start",
  },
  image: {
    objectFit: "cover",
    height: "38px",
    width: "38px",
    borderRadius: 0,
  },
  subImage: {
    objectFit: "cover",
    height: "28px",
    width: "28px",
    borderRadius: 0,
  },
  
  title: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#fff",
    fontFamily: "Ubuntu",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  footPart: {
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,
    background: "#222",
    px: "1rem",
    py: "0.2rem",
    button: {
      display: "flex",
      gap: "1rem",
    },
  },
  scrollbar: {
    mt: 2,
    overflowY: "auto",
    maxHeight: "200px",
    "&::-webkit-scrollbar": {
      width: "0",
    },
  },
  buttonScroll: {
    display: "flex",
    overflowX: "scroll",
    gap: 1,
    maxWidth:'100%',
    whiteSpace: "nowrap",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": { display: "none" },
  },
  searchBar: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#0b0b0b",
    borderRadius: "4px",
    padding: "4px 8px",
    width: "100%",
    mb: 2,
    gap: 1,
    color: "white",
  },
  selectBox: {
    color: "white",
    maxHeight:'46px',
    backgroundColor: "#2c2c2c",
    borderRadius: "4px",
    ".MuiOutlinedInput-notchedOutline": { border: "none" },
  },
  searchWrapper: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#000000",
    borderRadius: "4px",
    padding: "2px 8px",
    flex: 1,
  },
  footArea: { display: "flex", justifyContent: "space-between" },
  iconRyt: { display: "flex", gap: "1rem" },
  memberSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 1,
  },
  memberName: { fontSize: "12px", fontFamily: "inherit" },
  statusClr: {
    borderRadius: 1,
    fontSize: "12px",
    px: 1.5,
    py: 0.5,
    fontFamily: "inherit",
  },
  yesColor: {
    background: "#00ff0036",
    color: "#00ff00",
  },
  noColor: {
    background: "#e03f0036",
    color: "#e03f00",
  },
  toggler: {
    backgroundColor: "#2c2c2c",
    borderRadius: "4px",
    ".MuiToggleButton-root": {
      color: "white",
    },
  },
  responsiveContainerStyles : (toggleFilter, mobView) => ({
    maxWidth: {
      xs: "100%",
      sm: "100%",
      lg: toggleFilter ? "60%" : "70%",
      md: toggleFilter ? "60%" : "70%",
      xl: toggleFilter ? "60%" : "70%",
    },
    display: {
      xs: mobView ? "block" : "none",
      sm: mobView ? "block" : "none",
      lg: "block",
      md: "block",
      xl: "block",
    },
    transition: "max-width 1s ease, visibility 0.1ms ease, opacity 1s ease-out",
  }),  
  flexContainerStyles : (singleMarket) => ({
    display: "flex",
    gap: 1,
    alignItems: "center",
    maxWidth: singleMarket.isSingle ? "100%" : "auto",
    width: singleMarket.isSingle ? "100%" : "auto",
  }),
  statusStyles : (isSelected, hoverIndex, index, data, uiType, singleMarket) => ({
    ...styles.statusClr,
    color:
      isSelected || hoverIndex === index || data.outcome === "Yes"
        ? "#fff"
        : data.outcome === "No"
        ? "#fff"
        : "#fff",
    backgroundColor: isSelected
      ? "#d52b00" : 'trasnparent',
        // ? "#27ae60"
        // : "#e64800"
      // : hoverIndex === index || data.outcome === "Yes"
      // ? "transparent"
      // : null,
      // data.outcome === "No"
      // ? "#eb57571a"
      // : "#27ae601a",
    border:'0.5px solid #d52b00',  
    borderRadius: "5px",
    padding: "2px",
    fontSize: uiType === "OUTSIDE" || singleMarket.isSingle ? "14px" : "12px",
    minWidth: uiType === "OUTSIDE" || singleMarket.isSingle ? "120px" : "90px",
    minHeight: uiType === "OUTSIDE" || singleMarket.isSingle ? "40px" : "auto",
    maxWidth: singleMarket.isSingle ? "100%" : "auto",
    width: singleMarket.isSingle ? "100%" : "auto",
    "&:hover": {
      background: data.outcome === "Yes" ? "#e64800" : "#e64800",
      color: "#fff",
    },
  }),
  menuButtonStyles : (slug, item,subSlug) => ({
    flexShrink: 0,
    background: (slug || subSlug) === item.slug ? "#e03f00" : "#252222",
    color: "#fff",
    fontFamily: "inherit",
    textTransform: "none",
    "&:hover": {
      background: "#e03f00",
    },
  }),
  buttonStyles : (slug, data,mainCategory) => ({
    flexShrink: 0,
    background: slug === "all" || (mainCategory === slug) ? "#e03f00" : "#252222",
    color: "#fff",
    fontFamily: "inherit",
    textTransform: "none",
    "&:hover": {
      background: "#e03f00",
    },
  }),
  filterStyles : (toggleFilter, mobView) => ({
    p: 2,
    maxWidth: {
      xl: toggleFilter ? "30%" : "0",
      md: toggleFilter ? "30%" : "0",
      lg: toggleFilter ? "30%" : "0",
      sm: toggleFilter ? "100%" : "100%",
      xs: toggleFilter ? "100%" : "100%",
    },
    transition: "max-width 1s, visibility 0.1ms ease, opacity 1s ease-in",
    overflow: "hidden",
    width: {
      xl: toggleFilter ? "auto" : "0",
      md: toggleFilter ? "auto" : "0",
      lg: toggleFilter ? "auto" : "0",
      sm: toggleFilter ? "100%" : "100%",
      xs: toggleFilter ? "100%" : "100%",
    },
    display: {
      xl: toggleFilter ? "block" : "none",
      md: toggleFilter ? "block" : "none",
      lg: toggleFilter ? "block" : "none",
      sm: mobView ? "block" : "none",
      xs: mobView ? "block" : "none",
    },
  }),
  // buttonIntervalStyles : (selectedInterval, interval) => ({
  //   color: '#fff',
  //   background: selectedInterval === interval.value ? '#d52b00' : '#363535',
  //   borderColor: '#999!important',
  //   '&:hover': {
  //     background: '#d52b00',
  //   },
  // })
};

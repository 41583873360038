import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  CardActionArea,
  Avatar,
  Button,
  TextField,
  InputLabel,
  IconButton,
  CircularProgress,
} from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ChatIcon from "@mui/icons-material/Chat";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { styles } from "../../Css/styles";
import MarketList from "../Common/MarketList";
import SkeletonCard from "../../../../Components/CustLoader/Cardskeleton";
import CloseIcon from "@mui/icons-material/Close";
import SliderButtons from "./SlugTag";
import SearchBar from "./Searchbar";
import { formatDateString, formatToMillions, formatValue, probabilityToAmericanOdds, safeParseJSON } from "../../../../Utils/valuemakets";
import { useNavigate } from "react-router";
import HalfCircleProgress from "./CircleProgress";
import Gauge from "./CircleProgress";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MarketTable from "./MarketTable";
import ValuebetContext from "../../../../context/Valuebet/ValuebetContext";
import { BASEURL } from "../../../../API-Handle/config";
import { useDispatch, useSelector } from "react-redux";
import {  clearSlugData, mobileViewBtn, setClearEventData, setEventLoading, setFirstTimeLoader, setSearchKeyword, setSubSlug } from "../../../../redux/features/valuemarketSlice";
import { getEventsList } from "../../../../redux/services/valuebetmarketServices";
import SmSpinner from "../../../../Components/CustLoader/SmSpinner";
import moment from "moment";
import { APP_OFFSET } from "../../../../constant";

export default function Index({mobView,onSwitch,passDatatoParent}) {
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const scrollContainerRef = useRef(null); 
  const  [order, setOrder] = useState("volume24hr")
  const [search, setSearch] = useState("");
  const [view, setView] = useState("grid");
  const [offset, setOffset] = useState(0);
const [loadingMore, setLoadingMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [noMoreData, setNoMoreData] = useState(false);

  const {
    valueMarketDetails,
    mobileView,
    EventLoading: eventLoading,
    slugData: { data: getSlugData },
    eventCheck,
    noDataKeyWord,
    subSlug,
    eventsData: events,
    toggleFilter,
    mainCategory,
    firstTimeLoader,
  } = useSelector((state) => state.valuemarket);

  const handleSort = (value) => {
    setOrder(value)
  }

  const baseParams = useMemo(
    () => ({
      limit: 20,
      active: true,
      archived: false,
      closed: false,
      order: order,
      ascending: false,
      offset: 0,
      tag_slug: subSlug || undefined,
    }),
    [subSlug, order]
  );

  const getParams = () => {
    if (search) {
      return { _c: mainCategory, _q: search || noDataKeyWord, _p: 1 };
    }
    return { ...baseParams };
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      dispatch(setEventLoading(true));
      try {
        const params = getParams();
        await dispatch(getEventsList(params));
      } catch (error) {
        console.error("Error fetching initial events:", error);
      } finally {
        dispatch(setEventLoading(false));
        dispatch(setFirstTimeLoader(true));
      }
    };
    if (subSlug || search) {
      fetchInitialData();
    }
  }, [subSlug, search, firstTimeLoader]);

  const getLoadMoreParams = () => {
    return { ...baseParams, offset: offset + 20 };
  };

  const loadMoreData = async () => {
    if (loadingMore || noMoreData) return;
    setLoadingMore(true);
    try {
      const params = getLoadMoreParams();
     await dispatch(getEventsList(params, true));
      if (eventCheck) {
        setNoMoreData(true);
      } else {
        setOffset((prevOffset) => prevOffset + 20);
      }
    } catch (error) {
      console.error("Error fetching more events:", error);
      setNoMoreData(true);
    } finally {
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const container = scrollContainerRef.current;
      if (!container) return;
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollHeight - scrollTop <= clientHeight + 50) {
        loadMoreData();
      }
    };
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => container?.removeEventListener("scroll", handleScroll);
  }, [offset, subSlug, loadingMore, noMoreData]);
  
  const handleSlugChange = (slug) => { 
    dispatch(setSubSlug(slug))
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const valueType = (item) => {
    setSelectedOption((prev) => (prev === item ? null : item));
  };


  return (
    <Grid
      container
      spacing={2}
      mt="71px"
      mr={2}
      ml={0}
      sx={styles.responsiveContainerStyles(toggleFilter, mobView)}
      
    >
      <SearchBar
        view={view}
        handleSort={handleSort}
        order={order}
        handleSearch={handleSearch}
        handleViewChange={handleViewChange}
      />
       
      <SliderButtons
        data={getSlugData}
        slug={subSlug}
        handleSlugChange={handleSlugChange}
      />
      <Grid
        container
        spacing={1}
        my={1}
        ref={scrollContainerRef} // Attach the ref to the custom scroll container

        sx={{
          ml: {
            xs: 0,
            sm: 0,
            lg: 1,
            md: 1,
            xl: 2,
          },
          maxHeight: "calc(100vh - 200px)",
          overflowY: "auto",
          pr: 1,
          width:{
            xs:'100%'
          }
        }}
      >
        {eventLoading && events?.length === 0 
          ? Array.from({ length: 12 }).map((_, index) => (
              <SkeletonCard toggleFilter={toggleFilter} key={index} />
            ))
          : events?.map((item) => {
            // Check if the market length is 1 and the outcomePrices contain only 0 or 1
            if (
              item?.markets?.length === 1 &&
    item?.markets.every((row) => {
      const prices = row.outcomePrices ? safeParseJSON(row.outcomePrices) : [];
      return !prices.some((price) => {
        const probability = parseFloat(price);
        const americanOdds = probabilityToAmericanOdds(probability);
        if (americanOdds <= 0.0001) {
          return false;
        }
        return americanOdds !== 0 || americanOdds !== 1;
      });
    })
            ) 
            {
              return null; // Skip rendering this card
            }
          
            return (
              <Grid
                item
                lg={toggleFilter ? 6 : 6}
                xl={6}
                md={6}
                sm={6}
                xs={12}
                mb={{ xs: 0, lg: 0, md: 0 }}
                key={item.id}
                sx={styles.box}
              >
                <Card
                  sx={{
                    ...styles.cardStyle,
                    "&:hover": {
                      cursor:'pointer',
                      span: {
                        color: "#d52b00",
                      },
                    },
                  }}
                >
                  <CardContent sx={{ p: 2 }}>
                    { (
                      <Box sx={styles.top}>
                        <Avatar
                          onClick={() => setSelectedOption(item.id)}
                          src={item.image}
                          sx={styles.image}
                          style={{ cursor: "pointer" }}
                        />
                        <Typography
                          sx={styles.title}
                          variant="heading6"
                          onClick={()=>{
                            onSwitch();passDatatoParent(item)
                          }}
                        >
                          {item.title}
                        </Typography>
                      </Box>
                    )}
          
                    <Box>
                    <Box
  sx={{
    ...styles.scrollbar,
    mt: item?.markets?.length === 1 ? "58px" : "20px",
    maxHeight: item?.markets?.length <= 1 ? "100%" : "100px",
  }}
>
  {[...item.markets] // Properly spread the array
    .map((row) => {
      const formattedPrice = JSON.stringify([
        row.bestAsk.toString(),
        (1 + row.spread - row.bestAsk).toFixed(4),
      ]);
      return { ...row, formattedPrice };
    })
    .sort((a, b) => {
      const pricesA = a.formattedPrice ? safeParseJSON(a.formattedPrice) : [];
      const pricesB = b.formattedPrice ? safeParseJSON(b.formattedPrice) : [];
      const maxPriceA = pricesA.length > 0 ? Math.max(...pricesA.map(Number)) : 0;
      const maxPriceB = pricesB.length > 0 ? Math.max(...pricesB.map(Number)) : 0;
      return maxPriceA - maxPriceB;
    })
    .filter((row) => {
      const prices = row.formattedPrice ? safeParseJSON(row.formattedPrice) : [];
      return prices.some((price) => {
        const probability = parseFloat(price);
        const americanOdds = probabilityToAmericanOdds(probability);
        return americanOdds > 0.0001 && americanOdds !== 0 && americanOdds !== 1;
      });
    })
    .map((row, index) => (
      <MarketList
        key={index}
        singleMarket={{
          isSingle: item?.markets?.length <= 1,
          bestBid: row.bestBid,
          image: item.image,
          title: item.title,
        }}
        marketId={row.id}
        image={row.image}
        eventId={item.id}
        bestBid={row.bestBid}
        eventName={item.title}
        name={row.groupItemTitle}
        outcome={row.outcomes}
        token={row.clobTokenIds}
        price={row.formattedPrice}
        valueType={valueType}
        uiType={"INSIDE"}
      />
    ))}
</Box>

                    </Box>
                  </CardContent>
                  <Box sx={styles.footPart}>
                    <CardActionArea sx={styles.footArea}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 0.5,
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "12px",alignItems:'center', fontFamily: "inherit",gap:'0.2rem',display:'flex' }}
                        >
                          {/* $ {formatToMillions(item.volume)} VoL */}
                          <CalendarMonthIcon fontSize="medium"/>
                         Est. End Date : {moment.utc(item.endDate).format('MMM Do YYYY')}
                        </Typography>
                      </Box>
                      <Box sx={styles.iconRyt}>
                        <StarBorderIcon fontSize="small" />
                      </Box>
                    </CardActionArea>
                  </Box>
                </Card>
              </Grid>
            );
          })
          }
          {loadingMore && <Typography sx={{py:2,position:'sticky',bottom:0,width:'100%',textAlign:'center',fontSize:'12px'}}><SmSpinner inline={true}/></Typography>}
          {noMoreData && events.length > 0 && <Typography sx={{ py: 2, width: "100%", textAlign: "center", fontSize: "14px",fontFamily:'inherit' }}>No more data available</Typography>}

      </Grid>
      

    </Grid>
  );
}